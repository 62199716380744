// vuei18n/index.js

import Vue from 'vue'
import Vuei18n from 'vue-i18n'

Vue.use(Vuei18n)


// vuei18n/index.js
// 模拟从后台获取中英互译数据
/*
假设数据结构
{
  dictType: "common", // 分类
  dictLabel: "user", // 描述
  dictZhValue: "大栗子", // 中文值
  dictEnValue: "lily" // 英文值
}
*/
const i18nData = () => {
    return [
        {
            dictType: 'base',
            dictLabel: 'title',
            dictZhValue: '打托模拟工具',
            dictEnValue: '3D Pallet Simulation Tool'
        },
        {
            dictType: 'base',
            dictLabel: 'language_zh',
            dictZhValue: '切换中文',
            dictEnValue: 'Chinese'
        },
        {
            dictType: 'base',
            dictLabel: 'language_en',
            dictZhValue: '切换英文',
            dictEnValue: 'English'
        },
        {
            dictType: 'setting',
            dictLabel: 'pallet_setting',
            dictZhValue: '托盘设置',
            dictEnValue: 'Pallet Setting'
        },
        {
            dictType: 'setting',
            dictLabel: 'length',
            dictZhValue: '长',
            dictEnValue: 'Length'
        },
        {
            dictType: 'setting',
            dictLabel: 'wide',
            dictZhValue: '宽',
            dictEnValue: 'Wide'
        },
        {
            dictType: 'setting',
            dictLabel: 'high',
            dictZhValue: '高',
            dictEnValue: 'High'
        },
        {
            dictType: 'setting',
            dictLabel: 'rate',
            dictZhValue: '可超出比例',
            dictEnValue: 'Overstep Rate'
        },
        {
            dictType: 'setting',
            dictLabel: 'item_limit',
            dictZhValue: '产品总数量不能超过',
            dictEnValue: 'item limit '
        },
        {
            dictType: 'setting',
            dictLabel: 'start_simulation',
            dictZhValue: '生成结果',
            dictEnValue: 'Start Simulation'
        },
        {
            dictType: 'setting',
            dictLabel: 'single',
            dictZhValue: '单',
            dictEnValue: 'Single '
        },
        {
            dictType: 'setting',
            dictLabel: 'mixed',
            dictZhValue: '混合',
            dictEnValue: 'Mixed '
        },
        {
            dictType: 'item',
            dictLabel: 'number',
            dictZhValue: '产品编号',
            dictEnValue: 'Item No.'
        },
        {
            dictType: 'item',
            dictLabel: 'quantity',
            dictZhValue: '数量',
            dictEnValue: 'Quantity'
        },
        {
            dictType: 'item',
            dictLabel: 'length',
            dictZhValue: '长',
            dictEnValue: 'Length'
        },
        {
            dictType: 'item',
            dictLabel: 'wide',
            dictZhValue: '宽',
            dictEnValue: 'Wide'
        },
        {
            dictType: 'item',
            dictLabel: 'high',
            dictZhValue: '高',
            dictEnValue: 'High'
        },
        {
            dictType: 'item',
            dictLabel: 'weight',
            dictZhValue: '重量',
            dictEnValue: 'Weight'
        },
        {
            dictType: 'item',
            dictLabel: 'place_holder',
            dictZhValue: '请选择摆放方向',
            dictEnValue: 'Orientation Selector'
        },
        {
            dictType: 'item',
            dictLabel: 'length_perpendicular',
            dictZhValue: '长边垂直于托盘',
            dictEnValue: 'long side perpendicular to the pallet'
        },
        {
            dictType: 'item',
            dictLabel: 'wide_perpendicular',
            dictZhValue: '宽边垂直于托盘',
            dictEnValue: 'broadside perpendicular to the pallet'
        },
        {
            dictType: 'item',
            dictLabel: 'high_perpendicular',
            dictZhValue: '高边垂直于托盘',
            dictEnValue: 'high side perpendicular to the pallet'
        },
        {
            dictType: 'item',
            dictLabel: 'changeable',
            dictZhValue: '可改变朝向',
            dictEnValue: 'orientation is changeable'
        },
        {
            dictType: 'item',
            dictLabel: 'not_changeable',
            dictZhValue: '不可改变朝向',
            dictEnValue: 'orientation is not changeable'
        },
        {
            dictType: 'count',
            dictLabel: 'title',
            dictZhValue: '托盘数据统计',
            dictEnValue: 'Pallet Count'
        },
        {
            dictType: 'count',
            dictLabel: 'total',
            dictZhValue: '共',
            dictEnValue: 'Total'
        },
        {
            dictType: 'count',
            dictLabel: 'pallet',
            dictZhValue: '托',
            dictEnValue: 'pallet'
        },
        {
            dictType: 'count',
            dictLabel: 'time',
            dictZhValue: '耗时',
            dictEnValue: ' '
        },
        {
            dictType: 'count',
            dictLabel: 'second',
            dictZhValue: '秒',
            dictEnValue: 'second'
        },
        {
            dictType: 'count',
            dictLabel: 'display',
            dictZhValue: '托盘3D展示',
            dictEnValue: '3D Virtual Display'
        },
        {
            dictType: 'count',
            dictLabel: 'number',
            dictZhValue: '第',
            dictEnValue: 'No.'
        },
        {
            dictType: 'count',
            dictLabel: 'count',
            dictZhValue: '总计',
            dictEnValue: 'sum'
        },

    ]
}

const geti18nData = () => {
    const res = i18nData()
    const zh = {} // 存储中文
    const en = {} // 存储英文
    for (const item of res) {
        if (!zh[item.dictType]) {
            zh[item.dictType] = {}
            en[item.dictType] = {}
        }
        zh[item.dictType][item.dictLabel] = item.dictZhValue // 中文
        en[item.dictType][item.dictLabel] = item.dictEnValue // 英文
    }
    return {
        zh,
        en
    }
}

// vuei18n/index.js
const initVuei18n = () => {
    const { zh, en } = geti18nData()
    return new Vuei18n({
        locale: localStorage.getItem('locale') || 'zh', // 默认为中文
        messages: {
            zh,
            en
        }
    })
}

const i18n = initVuei18n()
export default i18n